// export let API_ENDPOINT = "https://staging.api.gold.paymeindia.in";
// export let API_ENDPOINT_IFSC ="https://api.staging.paymeindia.in";


export let API_ENDPOINT = "https://api-gold.paymeindia.in";

export let API_ENDPOINT_IFSC = "https://api.paymeindia.in"; //for production
/*
export let API_ENDPOINT;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.log("dev code")
    API_ENDPOINT = "https://staging.api.gold.paymeindia.in";
} else {
    console.log("dev Prod")
    API_ENDPOINT = "https://api-gold.paymeindia.in";
} */
